<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        What is the pH of a solution made by dissolving
        <number-value :value="mass" unit="\text{g}" />
        of sodium acetate,
        <stemble-latex content="$\ce{NaC2H3O2},$" />
        in enough water to make
        <number-value :value="volume" unit="\text{L}" />
        of solution?
        <stemble-latex content="$(\text{K}_\text{b} = 7.71\times 10^{-10})$" />
      </p>

      <calculation-input
        v-model="inputs.studentAnswer"
        prepend-text="$\text{pH:}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemSIU131_Task20',
  components: {
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswer: null,
      },
    };
  },
  computed: {
    mass() {
      return this.taskState.getValueBySymbol('mass').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
